/* @font-face { */
/* font-family: "Poppins";
  src: url("../fonts/Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLight.ttf");
  font-weight: 200;
} */

@font-face {
  font-family: "circular";
  src: url("../fonts/CircularStd-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "circular";
  src: url("../fonts/CircularStd-Medium.otf");
  font-weight: 400;
}

@font-face {
  font-family: "circular";
  src: url("../fonts/CircularStd-Book.otf");
  font-weight: 500;
}

@font-face {
  font-family: "circular";
  src: url("../fonts/CircularStd-Bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "circular";
  src: url("../fonts/CircularStd-Black.otf");
  font-weight: 700;
}

html,
body {
  /* overscroll-behavior: none; */
  width: 100vw !important;
  overflow-x: hidden;
  color-scheme: light;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  background: #000;
  letter-spacing: -0.5px;
  color: #fff;
}

button,
input {
  font-family: "circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1,
h2 {
  font-family: "circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0;
}

h3 h4,
h5,
h6,
p {
  font-family: "circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1b1b1b inset !important;
  -webkit-text-fill-color: white !important;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1b1b1b inset !important;
  -webkit-text-fill-color: white !important;
}

/* h1,
h2 {
  font-weight: bold;
}

h3,
h4 {
  font-weight: 500;
}

h5,
h6 {
  font-weight: 400;
} */

/* p {
  hyphens: auto;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select {
  box-shadow: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}
.toast__body {
  font-size: 15px;
}
.toast__container {
  width: 250px;
  z-index: 9999;
}

@media only screen and (max-width: 512px) {
  .Toastify__toast-container.toast__container {
    width: 50%;
    max-width: 400px;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 9999;
  }
}
/* 

.css-2b097c-container {
  width: 240px;
}

.slick-dots li {
  margin: 0 1px !important;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 15px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  content: '•';
  text-align: center;
  opacity: 1 !important;
  color: white !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: black !important;
}

.slick-dots {
  bottom: 10px !important;
}

@media (max-width: 768px) {
  .slick-dots {
    bottom: 8px !important;
  }
  .slick-dots li button:before {
    color: #1941d8 !important;
  }
}
 */
