@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .section {
    @apply h-[600px] md:h-[800px] lg:h-[450px];
  }
  .row {
    @apply flex;
  }
  .navbar__large {
    /* @apply fixed top-0 left-0 hidden lg:flex h-16 w-full items-center justify-between px-2 lg:pt-0 lg:px-24 z-[10]; */
    @apply sticky top-0 hidden lg:flex h-[75px] w-full items-center justify-between px-2 lg:px-5 text-black z-[99];
  }
  .steps {
    @apply w-[20%] mx-2 md:h-full flex md:flex-col items-center;
  }
  .steps-inner {
    @apply h-[189px] w-[200px] bg-[#FF8028] rounded-xl flex items-center justify-center;
  }
  .step-title {
    @apply text-[#401B01] md:mt-4 font-bold text-base md:text-center;
  }
  .step-text {
    @apply md:mt-2 md:text-center text-sm;
  }
  .stepsDivider {
    @apply h-[10%] w-1 md:w-[5%] md:h-[1px] border-[1px] border-dashed border-[#000] md:mt-[-90px];
  }
  .landingpage__footer__style {
    @apply w-[45%] md:max-w-[17%];
  }
}
